
import helpers from "@/helpers/common"
import { klona } from 'klona'
import Vue from "vue"
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff'
import { applyDiff } from "@/helpers/apply-diff"


function getNextOrder(zones) {
	if (typeof zones.length === 'undefined') {
		return (1)
	}

	var uniq = []
	// Push all the zone names and make sure they are unique
	for (var i = 0; i < zones.length; i++) {

		if (!Object.prototype.hasOwnProperty.call(uniq, zones[i].order)) {
			uniq[zones[i].order] = i
		}
	}

	for (var j = 1; j < 5000; j++) {
		if (!Object.prototype.hasOwnProperty.call(uniq, j)) {
			return j  //argh return j not i
		}
	}

	return zones.length + 1
}

function refreshLinkZones(page, layout, selectedZone) {
	// console.log('in refresh link zones')
	if (Array.isArray(page.link_zones) && page.link_zones.length > 0) {

		//var toRemove = [];
		for (var i = 0; i < page.link_zones.length; ++i) {
			if (typeof page.link_zones[i].id === "undefined") {
				page.link_zones[i].id = helpers.generateUUID()// 'Link' + i.toString();
				page.link_zones[i].name = 'Link ' + i.toString()
				page.link_zones[i].visible = true
				page.link_zones[i].merge = false
				page.link_zones[i].toAnchor = ''
			}

			if (typeof page.link_zones[i].order === "undefined") {
				page.link_zones[i].order = getNextOrder(layout) //pass layout to getNextOrder
			}

			if (page.link_zones[i].link_to_uri !== undefined) {
				page.link_zones[i].toAnchor = page.link_zones[i].link_to_uri
			}

			if (page.link_zones[i].link_to_page !== undefined) {
				page.link_zones[i].toAnchor = '#page_' + page.link_zones[i].link_to_page
				page.link_zones[i].y = page.link_zones[i].y2
			}
			// console.log('in refresh link zones find intersectors')

			/* Not necessary to remove intersecting zones
			var temp_remove = helpers.findIntersectors(page.link_zones[i], layout)

			if (temp_remove !== undefined && temp_remove.length > 0) {
				for (var d = 0; d < temp_remove.length; d++) {
					if (page.link_zones[i].id.toString() !== temp_remove[d].id.toString()) {
						toRemove.push(temp_remove[d])
					}
				}
			}
			*/
			layout.push(page.link_zones[i]);
		}
		/* Not necessary to remove intersecting zones
		for (d = 0; d < toRemove.length; d++) {
			for (i = 0; i < layout.length; i++) {
				if (layout[i].id.toString() === toRemove[d].id.toString()) {
					if (layout[i] != selectedZone) {
						layout.splice(i, 1);
					}
				}
			}
		}
		*/
	}
}

function refreshFieldZones(page, layout) {
	//do this if there are field_zones and they are set to visible on the page
	if (page.field_zones !== undefined && page.fields_visible) {
		for (var i = 0; i < page.field_zones.length; ++i) {
			if (typeof page.field_zones[i].id === "undefined") {
				page.field_zones[i].id = helpers.generateUUID()
			}
			if (typeof page.field_zones[i].order === "undefined") {
				page.field_zones[i].order = getNextOrder(layout)
			}

			var fld_exist = false

			for (var z = 0; z < layout.length; z++) {
				if (layout[z].name === page.field_zones[i].name) {
					fld_exist = true
				}
			}

			if (!fld_exist) {
				layout.push(page.field_zones[i])
			}
		}
	}
}

function refreshImageZones(page, layout) {
	// console.log('refreshing image zones!')
	if (page.image_zones !== undefined && page.image_zones.length > 0) {
		var images = undefined

		try {
			images = JSON.parse(page.image_zones)
		}
		catch (e) {
			// Already in json...
			images = page.image_zones
		}

		for (var i = 0; i < images.length; ++i) {
			// hide if images are not visible
			if (page.images_visible) {
				images[i].hideZone = false;
				images[i].visible = true;
			}
			else {
				images[i].hideZone = true;
				images[i].visible = false;
			}
			if (typeof images[i].id === "undefined") {
				images[i].id = helpers.generateUUID()
			}
			if (typeof images[i].order === "undefined") {
				images[i].order = getNextOrder()
			}

			var img_exist = false
			for (var z = 0; z < layout.length; z++) {
				if (layout[z].name === images[i].name) {
					img_exist = true
				}
			}

			if (!img_exist) {
				layout.push(images[i])
			}
		}
	}
}


const pageMutations = {
	/**
	 * @param  {} state
	 * @param  {} page
	 */

	/*
		IMPORTANT!!
		After we make changes to a zone ie. the selected zone we're manipulating, we need to have that change reflected
		in the layout.  altering the zone doesn't automatically change the stored value in the layout itself, 
		so we need to change explicitly.
	*/

	UPDATE_LAYOUT_ZONE(state, zone) {
		//param zone is the zone in the layout that we're updating and setting in the store

		//For vue2, the best method to update an object in an array while being reactive is to splice in the new obj
		//https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/#2-make-sure-to-update-arrays-and-objects-correctly-only-in-vue-2gv

		if (zone != undefined && zone != null) {
			for (var i = 0; i < state.layout.length; i++) {
				if (zone.id == state.layout[i].id) {
					// console.log('updating layout zone: ', state.layout[i])
					//new! make a new object based off of the existing zone and splice it in.  this will trigger updates and force re-draw
					var new_zone = Object.assign({}, zone)
					state.layout.splice(i, 1, new_zone)
					break
				}
			}
		}
	},

	/*
		need a way to update the page repeats after the page/layout is saved
	*/
	UPDATE_PAGE_REPEATS(state, value) {
		this.state.page.repeats = value
	},
	//remove an ignore zone. 
	REMOVE_IGNORE_ZONE(state, zone) {
		for (var i = 0; i < state.layout.length; i++) {
			if (state.layout[i].id.toString() === zone.id.toString()) {
				if (state.layout[i].type === 'ignore') {
					state.layout.splice(i, 1);
				}
			}
		}
		//finally, set selected zone to null
		pageMutations.SET_SELECTED_ZONE(null)
	},
	/*
		update the width height of the zone in the layout 
	*/
	UPDATE_RESIZED_ZONE(state, zone) {
		for (var i = 0; i < state.layout.length; i++) {
			if (zone.id == state.layout[i].id) {
				state.layout[i].w = Math.round(zone.w)
				state.layout[i].h = Math.round(zone.h)
				break
			}
		}
		//update the selectedZone as well
		if (state.selectedZone.id == zone.id) {
			state.selectedZone.w = Math.round(zone.w)
			state.selectedZone.h = Math.round(zone.h)
		}
	},
	/*
		update the x y coordinates of the zone in the layout
	*/
	UPDATE_MOVED_ZONE(state, zone) {
		for (var i = 0; i < state.layout.length; i++) {
			if (zone.id == state.layout[i].id) {
				state.layout[i].x = Math.round(zone.x)
				state.layout[i].y = Math.round(zone.y)
				break
			}
		}
		//update the selected zone as well
		if (state.selectedZone.id == zone.id) {
			state.selectedZone.x = Math.round(zone.x)
			state.selectedZone.y = Math.round(zone.y)
		}
	},

	SET_ML_TABLES(state, val) {
		state.mlTables = val
	},
	SET_TABLE_ZONE(state, val) {
		state.tableZone = val
	},
	SET_PAGE(state, page) {
		state.page = page
		if (page != null) {
			state.sensitivity = page.sensitivity
		}
	},
	MARK_PAGE(state, validated) {
		state.page = { ...state.page, validated: validated }
	},
	INIT_UNDO(state, layout) {
		// Init undo redo stack
		state.undoredoDone = []
		state.undoredoUndone = []
		state.initialLayout = klona(layout)
		state.lastSavedLayout = klona(layout)
	},
	ADD_UNDO(state, layout) {
		// Init undo redo stack
		const layout_copy = klona(layout)
		let res
		if (state.undoredoDone.length === 0) {
			res = diff(state.initialLayout, layout_copy)
		}
		else {
			res = diff(state.previousLayout, layout_copy)
		}

		if (Object.keys(res).length > 0) {
			let addToUndo = []
			for (var r in res) {
				if (res[r]) {

					if (Object.keys(res[r]).length == 1 && 'selected' in res[r]) {
						// console.log("Should not update undo here")
					}
					else if (Object.keys(res[r]).length == 1 && 'changed' in res[r]) {
						// console.log("Should not update undo here")
					}
					else if (Object.keys(res[r]).length == 2 && 'changed' in res[r] && 'selected' in res[r]) {
						// console.log("Should not update undo here")
					}
					else {
						addToUndo.push(res[r])
					}
				}
			}

			if (addToUndo.length > 0) {
				// console.log("Adding to undo ===>", res)
				state.undoredoDone.push(res)
				state.previousLayout = layout_copy
			}
		}

	},
	UNDO(state) {

		state.undoredoUndone.push(state.undoredoDone.pop())

		let undo_layout = klona(state.initialLayout)
		for (var i = 0; i < state.undoredoDone.length; i++) {
			undo_layout = applyDiff(undo_layout, state.undoredoDone[i])
		}

		state.layout = undo_layout
		if (state.selectedZone !== undefined) {
			var selectedZoneId = state.selectedZone.id
			for (var j = 0; j < state.layout.length; j++) {
				if (state.layout[j].id.toString() === selectedZoneId.toString()) {
					pageMutations.SET_SELECTED_ZONE(state, state.layout[j])
					break
				}
			}
		}

		refreshImageZones(state.page, state.layout)
		refreshFieldZones(state.page, state.layout)

	},
	REDO(state) {

		let lastop = state.undoredoUndone.pop()
		state.undoredoDone.push(lastop)

		let redo_layout = klona(state.layout)
		redo_layout = applyDiff(redo_layout, lastop)

		// const previous_layout = klona(state.undoredoDone[state.undoredoDone.length - 1])
		// console.log("resolveing previous layout", previous_layout)		
		state.layout = redo_layout

		if (state.selectedZone !== undefined) {
			var selectedZoneId = state.selectedZone.id
			for (var i = 0; i < state.layout.length; i++) {
				if (state.layout[i].id.toString() === selectedZoneId.toString()) {
					pageMutations.SET_SELECTED_ZONE(state, state.layout[i])
					break
				}
			}
		}
		refreshImageZones(state.page, state.layout)
		refreshFieldZones(state.page, state.layout)

	},
	SET_SAVED_LAYOUT(state, savedLayout) {
		state.lastSavedLayout = klona(savedLayout)
	},
	/**
	 * @param  {} state
	 * @param  {} newLayout
	 */
	SET_LAYOUT(state, newLayout) {
		// for (var i = 0; i < newLayout.length; i++) {
		//     newLayout[i].rect = {x: newLayout[i].x, y: newLayout[i].y, w: newLayout[i].w, h: newLayout[i].h }
		// }
		state.layout = newLayout
		refreshImageZones(state.page, state.layout)
		refreshFieldZones(state.page, state.layout)

		//test, let's make sure that the selectedZone gets updated.  this is the reverse of updating the selected zone
		if (state.selectedZone !== undefined && state.selectedZone != null) {
			var selectedZoneId = state.selectedZone.id
			for (var i = 0; i < state.layout.length; i++) {
				if (state.layout[i].id.toString() === selectedZoneId.toString()) {
					pageMutations.SET_SELECTED_ZONE(state, state.layout[i])
					break
				}
			}
		}
	},

	SET_CONTRAST_ZONES(state, contrastZones) {
		state.contrastZones = contrastZones
	},

	REFRESH_IMAGE_ZONES(state) {
		refreshImageZones(state.page, state.layout)
	},
	REFRESH_FIELD_ZONES(state) {
		refreshFieldZones(state.page, state.layout)
	},
	REFRESH_LINK_ZONES(state) {
		refreshLinkZones(state.page, state.layout, state.selectedZone)
	},

	REORDER_PAGE(state, autoOrder) {

		autoOrder = parseInt(autoOrder)
		// console.log('Reordering ' + autoOrder)

		var excludes = {}
		for (var i = 0; i < state.layout.length; i++) {
			if (state.layout[i].type === 'div') {
				const divChildrens = helpers.findIntersectors(state.layout[i], state.layout)
				Array.from(divChildrens).forEach((child) => {
					excludes[child.id] = 1
				})
			}
		}

		function reOrderByColumns(colCount, excludes) {

			var colWidth = state.page.width / colCount
			state.layout.sort(function (a, b) {
				if (a.y < b.y)
					return -1
				if (a.y > b.y)
					return 1
				return 0
			})

			var counter = 1
			var startCol = 0
			var endCol = colWidth

			for (var col = 0; col < colCount; col++) {
				startCol = (colWidth * col) - (60 / colCount)
				endCol = (colWidth * (col + 1)) - (60 / colCount)
				for (var i = 0; i < state.layout.length; i++) {

					if (excludes[state.layout[i].id] !== 1) {
						if (state.layout[i].x >= startCol && state.layout[i].x <= endCol) {
							state.layout[i].order = counter++
						}
					}
				}

			}
		}

		switch (autoOrder) {

			case 2:
			case "2 columns":
				reOrderByColumns(2, excludes)
				break

			case 3:
			case "3 columns":
				reOrderByColumns(3, excludes)
				break

			case 4:
			case "4 columns":
				reOrderByColumns(4, excludes)
				break

			case 1:
			case "Default - Top Down":
			default:
				var colWidth = state.page.width / 3
				state.layout.sort(function (a, b) {

					if (a.x < colWidth) {
						if (a.y - 10 < b.y)
							return -1;
						if (a.y - 10 > b.y)
							return 1;
						return 0;
					}
					else {
						if (b.x < colWidth) {
							if (a.y < b.y - 10)
								return -1;
							if (a.y > b.y - 10)
								return 1;
							return 0;
						}
						else {
							if (a.y < b.y)
								return -1;
							if (a.y > b.y)
								return 1;
							return 0;
						}
					}
				})

				for (var d = 0; d < state.layout.length; d++) {
					if (excludes[state.layout[d].id] !== 1) {
						state.layout[d].order = d + 1
					}
				}
				break
		}
	},
	SET_SHOW_TABLE_EDITOR(state, val) {
		state.showTableEditor = val
	},
	SET_FOCUSED_ZONE(state, val) {
		state.focusedZone = val
	},
	SET_SELECTED_ZONE(state, zone) {
		state.selectedZone = zone
		//make sure we update zone in the layout as the selected zone
		pageMutations.UPDATE_LAYOUT_ZONE(state, zone)
	},
	SET_SELECTED_ZONE_CAPTION(state, val) {
		state.selectedZone.caption = val
		//pageMutations.UPDATE_LAYOUT_ZONE(state, zone)
	},
	SET_SELECTED_LIST_ITEM(state, val) {
		state.selectedListItem = val
	},
	SET_SELECTED_ZONE_LIST(state, val) {
		state.selectedZoneList = val
	},
	SET_SELECTED_ZONE_MERGE(state, val) {
		// console.log('in set selected zone merge')
		state.selectedZone.merge = val
	},
	SET_SELECTED_ZONE_SUMMARY(state, val) {
		state.selectedZone.summary = val
		//pageMutations.UPDATE_LAYOUT_ZONE(state, zone)
	},
	SET_SENSITIVITY(state, index) {
		if (index > 0) {
			const allLayouts = JSON.parse(state.page.detected_layout)
			state.sensitivity = index
			state.layout = allLayouts[index]
			if (state.layout === undefined) {
				state.layout = []
			}
			//Testing refresh link zones here ie when sensitivity slider changes
			pageMutations.REFRESH_LINK_ZONES(state)
		}
		else {
			state.layout = []
		}

		pageMutations.SET_SELECTED_ZONE(state, undefined)
		pageMutations.SET_LAYOUT(state, state.layout)
	},
	SET_SENSITIVITY_ZONE(state, payload) {

		let zone = payload.zone
		const newSensitivity = payload.sensitivity

		const allLayouts = JSON.parse(state.page.detected_layout)
		// console.log(state.layout)
		var zoneCount = state.layout.length
		while (zoneCount--) {
			if (state.layout[zoneCount].id !== zone.id) {
				if (state.layout[zoneCount].senDetected !== undefined && state.layout[zoneCount].senDetected == zone.id) {
					state.layout.splice(zoneCount, 1)
				} else {
					if (state.layout[zoneCount].x >= zone.x && (state.layout[zoneCount].x + state.layout[zoneCount].w <= zone.x + zone.w) &&
						state.layout[zoneCount].y >= zone.y && state.layout[zoneCount].y + state.layout[zoneCount].h <= zone.y + zone.h) {
						state.layout.splice(zoneCount, 1)
					}
				}
			}
		}

		if (newSensitivity == 0) {
			//      $scope.clearLayout();

		}
		else {
			for (var i = 1; i < allLayouts[newSensitivity].length; i++) {
				var layZone = allLayouts[newSensitivity][i];
				if (layZone.x >= zone.x && (layZone.x + layZone.w <= zone.x + zone.w) &&
					layZone.y >= zone.y && layZone.y + layZone.h <= zone.y + zone.h) {

					var newObject = klona(layZone)
					newObject.id = helpers.generateUUID()
					newObject.senDetected = zone.id
					newObject.order = i
					newObject.name = 'Auto Detect Zone ' + i
					state.layout.push(newObject)
				}
			}

			// // Add the links back
			// for (var i = 0; i < $scope.link_zones.length; i++) {
			// 	var linkZone = $scope.link_zones[i];
			// 	if (linkZone.x >= zone.x && (linkZone.x + linkZone.w <= zone.x + zone.w) &&
			// 		linkZone.y >= zone.y && linkZone.y + linkZone.h <= zone.y + zone.h) {

			// 	var newObject = jQuery.extend(true, {}, linkZone);
			// 	newObject.id = generateUUID()
			// 	newObject.senDetected = zone.id;
			// 	newObject.order = i;
			// 	newObject.name = 'Auto Detect Zone ' + i;
			// 	state.layout.push(newObject);
			// 	}

			// }
			// // Add form items back
			// for (var i = 0; i < $scope.field_zones.length; i++) {
			// 	var fieldZone = $scope.field_zones[i];
			// 	if (fieldZone.x >= zone.x && (fieldZone.x + fieldZone.w <= zone.x + zone.w) &&
			// 		fieldZone.y >= zone.y && fieldZone.y + fieldZone.h <= zone.y + zone.h) {

			// 	var newObject = jQuery.extend(true, {}, fieldZone);
			// 	newObject.id = generateUUID()
			// 	newObject.senDetected = zone.id;
			// 	newObject.order = i;
			// 	newObject.name = 'Auto Detect Zone ' + i;
			// 	state.layout.push(newObject);
			// 	}
			// }

			pageMutations.SET_LAYOUT(state, state.layout)
		}


		// pageMutations.SET_SELECTED_ZONE(state, state.layout[newZoneIndex - 1])
	},

	//TODO: IS THIS CORRECT??
	UPDATE_ZONE_TYPE(state, newType) {

		state.selectedZone = { ...state.selectedZone, type: newType }

		if (newType === 'field') {
			state.selectedZone.v = 2
			if (state.selectedZone.field_type === undefined) {
				state.selectedZone = { ...state.selectedZone, field_type: 'text' }
			}

			if (state.selectedZone.f_f === undefined) {
				state.selectedZone = { ...state.selectedZone, f_f: { read_only: false, required: false } }
			}
		}
	},
	UPDATE_FIELD_TYPE(state, newType) {
		state.selectedZone = { ...state.selectedZone, field_type: newType }

		state.selectedZone.field_type = newType
		if (state.selectedZone.f_f === undefined) {
			state.selectedZone.f_f = { read_only: false, required: false }
		}

	},

	ADD_ZONE(state, payload) {

		// console.log("in page mutation ADD_ZONE. payload: ", payload)

		function getNextOrder() {
			if (typeof state.layout.length === 'undefined') {
				return (1);
			}

			var uniq = [];
			// Push all the zone names and make sure they are unique
			for (var i = 0; i < state.layout.length; i++) {
				if (!Object.prototype.hasOwnProperty.call(uniq, state.layout.order)) {
					uniq[state.layout[i].order] = i
				}
			}

			for (var j = 1; j < 5000; j++) {
				if (!Object.prototype.hasOwnProperty.call(uniq, j)) {
					return j
				}
			}

			return state.layout.length + 1;
		}
		function getNextName(page_idx) {
			var uniq = [];
			// Push all the zone names and make sure they are unique
			for (var i = 0; i < state.layout.length; i++) {
				if (!Object.prototype.hasOwnProperty.call(uniq, state.layout[i].name)) {
					uniq[state.layout[i].name] = i;
				}
			}

			var start_idx = state.layout.length + 1;
			for (var idx = state.layout.length + 1; idx < 5000; idx++) {
				var test_name = 'Page ' + page_idx + ' Zone ' + idx;
				if (!Object.prototype.hasOwnProperty.call(uniq, test_name)) {
					return test_name;
				}

			}
		}

		let newZone = {
			id: helpers.generateUUID(),
			name: getNextName(state.page.page_no),
			p: state.page.page_no,
			type: 'text',
			order: getNextOrder(),
			merge: false,
			breakLine: false,
			listType: 'u',
			tag: '<p>',
			altSelected: false,
			ocr: false,
			source: 'PDF',
			headerlevel: 1,
			visible: true,
			cols: [],
			rows: [],
			x: Math.round(payload.x),
			y: Math.round(payload.y),
			w: Math.round(payload.w),
			h: Math.round(payload.h)
		}
		const newZoneIndex = state.layout.push(newZone)
		pageMutations.SET_LAYOUT(state, state.layout)
		pageMutations.SET_SELECTED_ZONE(state, state.layout[newZoneIndex - 1])
	},
	REMOVE_ZONE(state, zone) {
		
		for (var i = 0; i < state.layout.length; i++) {
			if (state.layout[i].id.toString() === zone.id.toString()) {
				if (state.layout[i].type !== 'image') {
					// state.layout.splice(i, 1)
					state.layout[i].visible = false
					state.layout[i].deleted = true
				}
				break
			}
		}

		if (zone.id == state.selectedZone.id) {
			//if the selectedZone is a list, then delete the selectedZoneList and selectedListItem
			if (state.selectedZoneList != null && (state.selectedZone.id == state.selectedZoneList.id)) {
				state.selectedZoneList = undefined
				state.selectedListItem = undefined
			}
			//finally delete selectedZone if id matches
			state.selectedZone = undefined
		}
		//also need to do the focusedZone
		if (zone.id == state.focusedZone.id) {
			state.focusedZone = undefined
		}
	},
	SET_CUSTOM_PROGRESS(state, { zoneId }) {

		if (state.selectedZone.id === zoneId) {
			state.selectedZone = {
				...state.selectedZone,
				customText: '',
				customInProgress: true
			}
		}

		for (var i = 0; i < state.layout.length; i++) {
			if (state.layout[i].id.toString() === zoneId.toString()) {
				state.layout[i].customText = ''
				state.layout[i].customInProgress = true
				break
			}
		}
	},
	SET_CUSTOM_RESULT(state, { zone, customText }) {

		for (var i = 0; i < state.layout.length; i++) {
			if (zone.type === 'listitem') {
				if (state.layout[i].id.toString() === zone.parent.toString() && state.layout[i].listitems) {
					for (var index = 0; index < state.layout[i].listitems.length; index++) {
						if (state.layout[i].listitems[index].id === zone.id.toString()) {
							// console.log("List index item!?")
							state.layout[i].listitems[index].customInProgress = false
							if (zone.source == 'Custom') {
								state.layout[i].listitems[index].customText = customText
							} else {
								state.layout[i].listitems[index].actual_text = customText
							}
							break
						}
				}
	
				}
			}
			else if (state.layout[i].id.toString() === zone.id.toString()) {
				if (zone.source == 'Custom') {
					state.layout[i].customText = customText
				} else {
					state.layout[i].actual_text = customText
				}
				
				state.layout[i].customInProgress = false
				break
			}
		
		}

	},
	SET_OCR_ID(state, { zoneId, transactId }) {

		if (state.selectedZone.id === zoneId) {
			state.selectedZone = {
				...state.selectedZone, ocrText: '',
				ocrStatus: transactId, ocrInProgress: true,
				ocrError: undefined
			}
		}

		for (var i = 0; i < state.layout.length; i++) {
			if (state.layout[i].id.toString() === zoneId.toString()) {
				state.layout[i].ocrStatus = transactId
				state.layout[i].ocrText = ''
				state.layout[i].ocrInProgress = true
				state.layout[i].ocrError = undefined
				break
			}
		}
	},

	/*
		This isn't working correctly for setting ocr on lists

	SET_OCR_RESULT(state, { zoneId, ocrText }) {

		let ocrError = undefined
		if (ocrText === "") {
			ocrError = 'no_content'
		}
		console.log("zoneId: ", zoneId)
		console.log("selectedZone: ", state.selectedZone)
		/*
		if (state.selectedZone.id === zoneId) {
			console.log('in found selectedZone by id')
			state.selectedZone = {
				...state.selectedZone, ocrText: ocrText,
				ocrStatus: undefined, ocrInProgress: false,
				ocrError: ocrError
			}
		}
		*//*
for (var i = 0; i < state.layout.length; i++) {
if (state.layout[i].id.toString() === zoneId.toString()) {

state.layout[i].ocrText = ocrText
state.layout[i].ocrStatus = undefined
state.layout[i].ocrInProgress = false
state.layout[i].ocrError = ocrError
break
}
}

},
*/
	REMOVE_UNDERLYING_ZONE(state, { zone }) {

		//const toRemove = helpers.findIntersectors(zone, state.layout)
		const toRemove = helpers.findIntersectorsExcludingZone(zone, state.layout)
		for (var d = 0; d < toRemove.length; d++) {
			for (var i = 0; i < state.layout.length; i++) {
				if (state.layout[i].id.toString() === toRemove[d].id.toString()) {
					if (state.layout[i] != zone) {
						state.layout.splice(i, 1)
					}
				}
			}
		}
	},

	/*
		Section for mutating lists
	*/

	/*
	param: list ie the zone of type list
	*/
	RESIZE_LIST_ITEMS(state, list) {
		list.listitems.sort(function (a, b) {
			if (a.y < b.y)
				return -1;
			if (a.y > b.y)
				return 1;
			return 0;
		})

		var last_y = 0
		var remove_start = -1
		var spliiter_offset = 2

		for (var i = 0; i < list.splitters.length; i++) {
			list.listitems[i].listItemIndex = i
			list.listitems[i].h = list.splitters[i] - last_y
			list.listitems[i].w = list.w
			if (list.listitems[i].lbl !== undefined) {

				if (list.listitems[i].lbl.h === undefined) {
					console.log('Height is undefined')

				}
				if (list.listitems[i].lbl.y > list.listitems[i].y + list.listitems[i].h) {
					// Lbl is outside the listitem
					list.listitems[i].lbl.y = list.listitems[i].y + spliiter_offset;
				}

				if (list.listitems[i].lbl.y + list.listitems[i].lbl.h > list.listitems[i].y + list.listitems[i].h) {
					// Lbl height is outside the listitem
					list.listitems[i].lbl.h = list.listitems[i].h
				}
			}

			if (list.listitems[i + 1] !== undefined) {
				list.listitems[i + 1].y = list.splitters[i]
			}

			last_y = list.splitters[i]

			// If the list is resized over actual splitters, remove the splitters and items under
			if (list.y + list.splitters[i] > (list.y + list.h) && remove_start < 0) {
				remove_start = i
			}
		}

		if (remove_start > -1) {
			// Remove items that are out of bounds
			list.listitems.splice(remove_start + 1, list.listitems.length - remove_start)
			list.splitters.splice(remove_start, list.splitters.length - remove_start)
			// Last Item, splitters is one less that listitems
			list.listitems[list.splitters.length].h = list.h - list.splitters[list.splitters.length - 1]
			list.listitems[list.splitters.length].w = list.w
		}
		else {
			// Last Item, splitters is one less that listitems
			list.listitems[list.splitters.length].h = list.h - last_y
			list.listitems[list.splitters.length].w = list.w
		}

		//update the zone in the layout
		pageMutations.UPDATE_LAYOUT_ZONE(state, list)
	},
	UPDATE_LIST_SPLITTER(state, zone) {

		for (var z = 0; z < state.layout.length; z++) {
			if (state.layout[z].id.toString() === zone.id.toString()) {
				var state_zone = state.layout[z]
				state_zone.listSensitivity = zone.listSensitivity
				if (zone.splitters != null && zone.splitters != undefined && zone.splitters.length > 0) {
					var splitterObjs = []
					for (var i = 0; i < zone.splitters.length; i++) {
						var splitterObj = { y: zone.splitters[i], id: i }
						splitterObjs.push(splitterObj)
					}
					state_zone.splitterObjs = splitterObjs
					// console.log('zone after adding splitterObj: ', state_zone)
				}
			}
		}
	},
	//from listItemCountChanged in document_controllers
	UPDATE_LIST_ITEM_COUNT(state, zone) {
		var getSplitterObjs = function (splitters) {
			var splitterObjs = []
			for (var i = 0; i < zone.splitters.length; i++) {
				var splitterObj = { y: zone.splitters[i], id: i }
				splitterObjs.push(splitterObj)
			}
			return splitterObjs

		}

		// Make sure the list items are in the right order, they are constantly reorder
		if (zone.listitems !== undefined) {
			//convert from str
			zone.listItemsCount = parseInt(zone.listItemsCount)
			// console.log('in UPDATE_LIST_ITEM_COUNT. zone.listItemsCount is: ', zone.listItemsCount)
			if (zone.listItemsCount > zone.listitems.length) {
				// Adding a new listitem
				// Sort by reversed height, Find the largest list item and split in half
				zone.listitems.sort(function (a, b) {
					if (a.h > b.h)
						return -1
					if (a.h < b.h)
						return 1
					return 0
				})

				var largestItemIndex = zone.listitems[0].listItemIndex
				var newItemHeight = parseInt(zone.listitems[0].h / 2)

				// Now that we have the largest, Re sort listitems on their index
				zone.listitems.sort(function (a, b) {
					if (a.listItemIndex < b.listItemIndex)
						return -1
					if (a.listItemIndex > b.listItemIndex)
						return 1
					return 0
				});

				// Resize largest item
				zone.listitems[largestItemIndex].h = newItemHeight
				// Add the new splitter
				zone.splitters.push(zone.listitems[largestItemIndex].y + zone.listitems[largestItemIndex].h)

				// Insert the new item
				var newItem = {
					id: helpers.generateUUID(),
					type: "listitem",
					name: zone.name + ' LI ' + zone.listitems.length + 1,
					listItemIndex: largestItemIndex,
					parent: zone.id,
					order: zone.listitems[largestItemIndex].order + 1,
					uselbl: false,
					merge: false,
					x: 0,
					y: parseInt(zone.listitems[largestItemIndex].y + zone.listitems[largestItemIndex].h),
					h: newItemHeight,
					w: zone.listitems[largestItemIndex].w,
					source: "PDF",
					//lbl: { id:helpers.generateUUID(), type: "lbl", x: 1, y: 1, w: 15, h: 15 },
				}

				//need to add the label if list or artifact if toc
				if (zone.type == 'list') {
					// console.log('adding lbl to list listitem')
					newItem.lbl = { id: helpers.generateUUID(), type: "lbl", x: 1, y: 1, w: 15, h: 15 }
				}
				else if (zone.type == 'toc') {
					// console.log('adding artifact to toc listitem')
					newItem.artifact = { id: helpers.generateUUID(), type: "artifact", x: 1, y: 1, w: 15, h: 15 }
				}



				zone.listitems.splice(largestItemIndex + 1, 0, newItem)

				if (zone.type == 'list') {
					console.log('type is list')
				}
				else if (zone.type == 'toc') {
					console.log('type is toc!!')
				}

				// Incease the listindex for the rest of the array
				for (var index = largestItemIndex + 1; index < zone.listitems.length; index++) {
					zone.listitems[index].listItemIndex++
					zone.listitems[index].order++
				}

				// Re-sort splitters based on value
				zone.splitters.sort(function (a, b) {
					if (a < b)
						return -1
					if (a > b)
						return 1
					return 0
				})

				//do the same for zone.splitterObjs.  just replace the splitterObjs array.
				zone.splitterObjs = getSplitterObjs(zone.splitters)
				pageMutations.RESIZE_LIST_ITEMS(state, zone)
			}
			else if (zone.listItemsCount > 0) {

				// Removing a listitem
				// Sort by height to find the smallest item
				zone.listitems.sort(function (a, b) {
					if (a.h < b.h)
						return -1
					if (a.h > b.h)
						return 1
					return 0
				})

				var smallestItemIndex = zone.listitems[0].listItemIndex
				var addThisHeight = zone.listitems[0].h + 2 // Add 2 for the splitter

				// Resort items
				zone.listitems.sort(function (a, b) {
					if (a.listItemIndex < b.listItemIndex)
						return -1
					if (a.listItemIndex > b.listItemIndex)
						return 1
					return 0
				});

				if (zone.listitems.length > 1) {
					// More than one item

					if (smallestItemIndex == 0) {
						// First item removed
						zone.listitems[smallestItemIndex + 1].y = 0
						zone.listitems[smallestItemIndex + 1].h += parseInt(addThisHeight)

						// Remove item
						zone.listitems.splice(smallestItemIndex, 1)
						// Remove splitter
						zone.splitters.splice(smallestItemIndex, 1)
					}
					else if (smallestItemIndex + 1 == zone.listitems.length) {
						// Last item removed
						// Add removed height to previous item
						zone.listitems[smallestItemIndex - 1].h += parseInt(addThisHeight)

						// Remove item
						zone.listitems.splice(smallestItemIndex, 1)
						// Remove splitter
						zone.splitters.splice(smallestItemIndex - 1, 1)
					}
					else {
						// In between
						// console.log('In between removed');
						// Going up or down, choose the smallest neighbor
						var previous_height = zone.listitems[smallestItemIndex - 1].h
						var next_height = zone.listitems[smallestItemIndex + 1].h

						if (previous_height < next_height) {
							// Going up
							zone.listitems[smallestItemIndex - 1].h += parseInt(addThisHeight)
							// Remove item
							zone.listitems.splice(smallestItemIndex, 1)
							// Remove splitter
							zone.splitters.splice(smallestItemIndex - 1, 1)
						}
						else {
							// Going down
							zone.listitems[smallestItemIndex + 1].y = 0
							zone.listitems[smallestItemIndex + 1].h += parseInt(addThisHeight)

							// Remove item
							zone.listitems.splice(smallestItemIndex, 1)
							// Remove splitter
							zone.splitters.splice(smallestItemIndex, 1)
						}
					}
					//do the same for zone.splitterObjs.  just replace the splitterObjs array.
					zone.splitterObjs = getSplitterObjs(zone.splitters)
					pageMutations.RESIZE_LIST_ITEMS(state, zone)
				}
				else {
					// We need to keep 1 item
					// Remove listitem
					zone.listitems.splice(smallestItemIndex, 1)
					zone.listitems[0].y = 0
					zone.listitems[0].h = zone.h
				}

				// Re index items
				for (index = 0; index < zone.listitems.length; index++) {
					zone.listitems[index].listItemIndex = index
				}
			}
		}
		else {
			// No list items, create a new one
			zone.listitems = [{
				id: helpers.generateUUID(),
				type: "listitem",
				name: zone.name + ' LI 1',
				listItemIndex: 0,
				parent: zone.id,
				order: zone.order + 1,
				uselbl: false,
				merge: false,
				x: 0,
				y: 0,
				h: parseInt(zone.h / 2),
				w: zone.w,
				source: "PDF",
				//lbl: { id:helpers.generateUUID(), type: "lbl", x: 1, y: 1, w: 15, h: 15 },
			}];

			//need to add the label if list or artifact if toc
			if (zone.type == 'list') {
				// console.log('adding lbl to list listitem')
				zone.listitems[0].lbl = { id: helpers.generateUUID(), type: "lbl", x: 1, y: 1, w: 15, h: 15 }
			}
			else if (zone.type == 'toc') {
				// console.log('adding artifact to toc listitem')
				zone.listitems[0].artifact = { id: helpers.generateUUID(), type: "artifact", x: 1, y: 1, w: 15, h: 15 }
			}

			zone.listItemsCount = 1;
			zone.splitters = [(parseInt(zone.h / 2))]
			zone.splitterObjs = getSplitterObjs(zone.splitters)
		}

		//pageMutations.UPDATE_LAYOUT_ZONE(state, zone)
		pageMutations.SET_SELECTED_ZONE(state, zone)
	},

	/*
		From document_controllers zonePositionArtefactChange
	*/
	UPDATE_ARTIFACT_ZONE_POSITION(state, listItem) {
		if (listItem !== undefined && listItem.artifact !== undefined) {
			if (listItem.artifact.w > listItem.w - listItem.artifact.x) {
				listItem.artifact.w = listItem.w - listItem.artifact.x;
			}
			if (listItem.artifact.h === undefined) {
				console.log('Height is undefined')
			}
			else {
				if (listItem.artifact.h > listItem.h - listItem.artifact.y) {
					listItem.artifact.h = listItem.h - listItem.artifact.y;
				}
			}

		}
	},
	/*
		From document_controllers zonePositionLblChange
	*/
	UPDATE_LBL_ZONE_POSITION(state, listItem) {
		// console.log('in change label zone position')
		if (listItem !== undefined && listItem.lbl !== undefined) {

			if (listItem.lbl.w > listItem.w - listItem.lbl.x) {
				listItem.lbl.w = listItem.w - listItem.lbl.x;
			}
			if (listItem.lbl.h === undefined) {
				listItem.lbl.h = 5;
			}
			else {
				if (listItem.lbl.h > listItem.h - listItem.lbl.y) {
					listItem.lbl.h = listItem.h - listItem.lbl.y;
				}
			}
		}
		//finally, update it
		//pageMutations.SET_SELECTED_LIST_ITEM(state, listItem)
	},

	SET_SELECTED_CELL(state, cell) {
		state.selectedTableCell = cell
	},
	UPDATE_SELECTED_CELL_SOURCE(state, source) {
		state.selectedTableCell = { ...state.selectedTableCell, source }
	},
	UPDATE_SELECTED_CELL_CUSTOM_TEXT(state, customText) {
		if (state.selectedTableCell.id) {
			state.selectedTableCell = { ...state.selectedTableCell, customText }
			var row = parseInt(state.selectedTableCell.id.split('_')[0].replace('r', ''))
			var col = parseInt(state.selectedTableCell.id.split('_')[1].replace('c', ''))
			state.selectedZone.rows[row].cells[col].customText = customText
		}
	},
	UPDATE_SELECTED_CELL_ACTUAL_TEXT(state, customText) {
		if (state.selectedTableCell.id) {
			state.selectedTableCell = { ...state.selectedTableCell, actual_text: customText }
			var row = parseInt(state.selectedTableCell.id.split('_')[0].replace('r', ''))
			var col = parseInt(state.selectedTableCell.id.split('_')[1].replace('c', ''))
			state.selectedZone.rows[row].cells[col].actual_text = customText
		}
	},
	UPDATE_SELECTED_CELL_OCR_TEXT(state, ocrText) {
		if (state.selectedTableCell.id) {
			state.selectedTableCell = { ...state.selectedTableCell, ocrText: ocrText }
			var row = parseInt(state.selectedTableCell.id.split('_')[0].replace('r', ''))
			var col = parseInt(state.selectedTableCell.id.split('_')[1].replace('c', ''))
			state.selectedZone.rows[row].cells[col].ocrText = ocrText
		}
	},	
	UPDATE_SELECTED_CELL_CALLING_OCR(state, callingOcr) {
		// console.log("Changing ocr state for ", state.selectedTableCell, " to ", callingOcr)
		state.selectedTableCell = { ...state.selectedTableCell, callingOcr }
	},
	UPDATE_SELECTED_CELL_OCR_STATUS(state, transactId) {
		if (state.selectedTableCell.id) {
			// console.log("Updating cell  status here")
			var row = parseInt(state.selectedTableCell.id.split('_')[0].replace('r', ''))
			var col = parseInt(state.selectedTableCell.id.split('_')[1].replace('c', ''))
			state.selectedZone.rows[row].cells[col].ocrStatus = transactId
			state.selectedZone.rows[row].cells[col].callingOcr = true
			state.selectedTableCell = { ...state.selectedTableCell, ocrStatus: transactId, callingOcr: true }
		}
	},
	UPDATE_CELL_OCR_TEXT(state, cb_message) {
		var tableZone = state.selectedZone;

		// Update table cell
		for (var row = 0; row < tableZone.rows.length; row++) {
			if (tableZone.rows[row].cells !== undefined) {
				for (var c = 0; c < tableZone.rows[row].cells.length; c++) {
					if (tableZone.rows[row].cells[c] !== undefined && tableZone.rows[row].cells[c].ocrStatus === cb_message.transactId) {
						tableZone.rows[row].cells[c].ocrText = cb_message.ocrText
						tableZone.rows[row].cells[c].ocrStatus = undefined
						tableZone.rows[row].cells[c].callingOcr = true
						if (tableZone.rows[row].cells[c].ocrText == "") {
							tableZone.rows[row].cells[c].ocrError = 'No content, resize or move the zone and retry'
						}
						else {
							tableZone.rows[row].cells[c].source = 'OCR'
						}
						break;
					}
				}
			}
		}

		// Update selected cell if it matches (User didn't select another cell)
		if (state.selectedTableCell && state.selectedTableCell.ocrStatus === cb_message.transactId) {
			state.selectedTableCell = {
				...state.selectedTableCell,
				ocrText: cb_message.ocrText,
				ocrStatus: undefined,
				callingOcr: false
			}
		}

	},
	CANCEL_CELL_CALLING_OCR(state, cell) {
		// This will update a cell that is not selected anymore
		var tableZone = state.selectedZone;

		// Update table cell
		for (var row = 0; row < tableZone.rows.length; row++) {
			if (tableZone.rows[row].cells !== undefined) {
				for (var c = 0; c < tableZone.rows[row].cells.length; c++) {
					if (tableZone.rows[row].cells[c] !== undefined && tableZone.rows[row].cells[c].id === cell.id) {
						tableZone.rows[row].cells[c].ocrStatus = undefined
						tableZone.rows[row].cells[c].callingOcr = false
						break;
					}
				}
			}
		}

		// Update selected cell if it matches (User didn't select another cell)
		if (state.selectedTableCell && state.selectedTableCell.id === cell.id) {
			state.selectedTableCell = {
				...state.selectedTableCell,
				ocrStatus: undefined,
				callingOcr: false
			}
		}

	},
	UPDATE_PAGE_OCR_PROGRESS_MSG(state, { pageProgress, pageProgressMessage }) {
		state.ocrAllZonesStatus = {
			...state.ocrAllZonesStatus,
			pageProgress,
			pageProgressMessage
		}
	},
	INIT_PAGE_OCR_PROGRESS(state, totalZones) {
		state.ocrAllZonesStatus = {
			...state.ocrAllZonesStatus,
			totalZones,
			inProgress: true,
			zonesProcessed: 0,
			pageProgress: 0
		}
	},
	UPDATE_ZONE_OCR_STATUS(state, { id, transactId }) {
		for (let idx = 0; idx < state.layout.length; idx++) {
			if (state.layout[idx].id === id) {
				state.layout[idx] = { ...state.layout[idx], ocrStatus: transactId }
				break
			}

		}
	},
	INCREMENT_PAGE_OCR_STATUS(state, zone) {
		// console.log("Updating progress", state.ocrAllZonesStatus.zonesProcessed + 1)

		// if (state.ocrAllZonesStatus.zonesProcessed + 1 >= state.ocrAllZonesStatus.totalZones) {

		// 	setTimeout(function () {
		// 		state.ocrAllZonesStatus = {
		// 			...state.ocrAllZonesStatus,
		// 			inProgress: false,
		// 			pageProgress: 100
		// 		}
		// 	}, 2000)
		// }
		// else {
		// 	state.ocrAllZonesStatus = {
		// 		...state.ocrAllZonesStatus,
		// 		inProgress: true,
		// 		zonesProcessed: state.ocrAllZonesStatus.zonesProcessed + 1,
		// 		pageProgress: (100 / state.ocrAllZonesStatus.totalZones) * state.ocrAllZonesStatus.zonesProcessed + 1
		// 	}
		// }

	},
	CALL_BACK_OCR(state, message) {
		let matched = false

		for (var index = 0; index < state.layout.length; index++) {
			var zone = state.layout[index]

			if ((zone.type === 'list' || zone.type === 'toc') && zone.listitems !== undefined) {
				for (var list_index = 0; list_index < zone.listitems.length; list_index++) {

					if (zone.listitems[list_index].ocrStatus !== undefined && zone.listitems[list_index].ocrStatus === message.data.transactId) {
						state.layout[index].listitems[list_index].ocrText = message.data.ocrText
						state.layout[index].listitems[list_index].ocrStatus = undefined
						state.layout[index].listitems[list_index].ocrInProgress = false
						matched = true
						break
					}

				}
			}
			else {
				if (zone.ocrStatus !== undefined && zone.ocrStatus === message.data.transactId) {
					zone.ocrText = message.data.ocrText
					zone.ocrStatus = undefined
					zone.ocrInProgress = false
					if (zone.id === state.selectedZone.id) {
						state.selectedZone.ocrText = zone.ocrText
						state.selectedZone.ocrStatus = zone.ocrStatus
						state.selectedZone.ocrInProgress = zone.ocrInProgress
					}
					matched = true
					break
				}

			}
			if (matched) {
				break
			}
		}

		if (state.ocrAllZonesStatus.inProgress) {
			if (state.ocrAllZonesStatus.zonesProcessed + 1 >= state.ocrAllZonesStatus.totalZones) {

				setTimeout(function () {
					state.ocrAllZonesStatus = {
						...state.ocrAllZonesStatus,
						inProgress: false,
						pageProgress: 100
					}
				}, 2000)
			}
			else {
				state.ocrAllZonesStatus = {
					...state.ocrAllZonesStatus,
					inProgress: true,
					zonesProcessed: state.ocrAllZonesStatus.zonesProcessed + 1,
					pageProgress: (100 / state.ocrAllZonesStatus.totalZones) * state.ocrAllZonesStatus.zonesProcessed + 1
				}
			}
		}

	},
}

export default pageMutations