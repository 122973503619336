<template>
	<svg :id="zone.id" :x="zone.x" :y="zone.y" :width="zone.w" :height="zone.h" v-on:keyup.enter="zoneSelected(zone)"
		@dblclick="onDoubleClick" @focus="onFocus" @mousedown.stop="onMouseDown">

		<g v-show="(resizingZone === undefined || resizingZone.id !== zone.id ) && zone.order && showZoneOrder && zone.type != 'list'">
        	<rect :x="(zone.w - 2) - (5 * getZoneOrderLength(zone))" y="1" :width="5 + (5 * getZoneOrderLength(zone))" height="12"
              	style=" fill: #00FA8C; opacity: 0.60; caret-color: transparent;"/>
        	<text>
				<tspan xml:space="preserve" text-anchor="end" font-family="Noto Sans JP" font-size="10" font-weight="bold"  stroke-width="0"
				:x="zone.w - 1" y="10" stroke="#000" fill="#000000"
				@mousedown.stop="onMouseDown" style="cursor: default;">
				{{ zone.order }} 
				</tspan>
        	</text>
        </g>
		
		<!-- THE BASIC ZONE RECT ALWAYS COME FIRST -->
		<!-- need to set the generic zone fill color and stroke color first, then change with classes -->
		<rect :class="getZoneClass" :id="zone.id" :order="zone.order" fill="#e8ffb657"
			:width="zone.w" :height="zone.h" x=0 y=0 border="0" />

		<path v-if="!creating && !resizing && resizableZoneType(zone)"
          :id=rightEdgePathName
          :d=rightEdgeDValue
          style="cursor: ew-resize; stroke: rgb(212 212 212 / 15%); strokeWidth: 3"
		  @mousedown.stop="onResizeRight"
        />

		<path v-if="!creating && !resizing && resizableZoneType(zone)"
          :id=bottomEdgePathName
          :d=bottomEdgeDValue
          style="cursor: ns-resize; stroke: rgb(212 212 212 / 15%); strokeWidth: 3"
		  @mousedown.stop="onResizeBottom"
        />

		<!-- THIS IS THE LONG GREEN BOX ON THE LEFT SIDE OF A LIST ZONE  -->
		<!-- if we put it first, the zone border will be on top, not hidden by the green -->

		<!-- removed per ryan
		<rect class="zone-list left" fill="#67ffae" v-show="zone.type == 'list' || zone.type == 'toc'"
			:x="zone.selected ? 1 : 1" :y="zone.selected ? 1 : 1" :height="zone.selected ? zone.h - 2 : zone.h - 2" border="0"
			stroke-width="1" />
		-->

		<!-- THE TEXT BOXES FOR ZONE TYPE AND ORDER ARE ALWAYS TOGETHER -->
		<!--leftmost zone text info
				this is defined by the  <defs> <filter id="solid" object defined in SVGZones.vue			
		-->

		<!-- if it's a list, we move the x over 5%.  Removed!
		<text fill="#00444" v-show="showZoneType">
			<tspan xml:space="preserve" text-anchor="start" font-family="Noto Sans JP" font-size="8" stroke-width="0"
				:x="zone.type == 'list' || zone.type == 'toc' ? (zone.w * .05) : 1" :textLength="5 * getZoneTypeAbbrLength(zone)"
				y="8" stroke="#000" fill="#000000" filter="url(#solid_green)" @mousedown.stop="onMouseDown"
				style="cursor: default;opacity: 0.65;">
				{{ getZoneTypeAbbr(zone) }} </tspan>
		</text>
		-->

		<!-- we removed the long left-hand green bar for list and toc, so don't move label over now-->
		<text fill="#00444" v-show="showZoneType" style="caret-color: transparent;">
			<tspan xml:space="preserve" text-anchor="start" font-family="Noto Sans JP" font-size="10" font-weight="bold" stroke-width="0"
				x="1" :textLength="5 * getZoneTypeAbbrLength(zone)"
				y="10" stroke="#000" fill="#000000" filter="url(#solid_green)" @mousedown.stop="onMouseDown"
				style="opacity: 0.65;">
				{{ getZoneTypeAbbr(zone) }} </tspan>
		</text>

		<!--rightmost zone text info this is defined by the <defs> <filter id="solid" object defined in SVGZones.vue -->
		<!-- <rect fill="rgba(124,240,10,0.5)" x="96%" y="1" width="20" height="20" v-show="!resizing && zone.order && showZoneOrder && zone.type != 'list'"  style="caret-color: transparent;">
			<text fill="#00444">
				<tspan xml:space="preserve" text-anchor="end" font-family="Noto Sans JP" font-size="10" font-weight="bold"  stroke-width="0"
				x="100%" :textLength="5 * getZoneOrderLength(zone)" y="8" stroke="#000" fill="#000000"
				
				@mousedown.stop="onMouseDown" style="cursor: default;">
				{{ zone.order }} 
				</tspan>
			</text>
		</rect> -->



		<text fill="#00444" v-show="!resizing && showZoneOrder && zone.type == 'list'"  style="caret-color: transparent;">
			<tspan xml:space="preserve" text-anchor="end" font-family="Noto Sans JP" font-size="8" stroke-width="0"
				x="100%" :textLength="5 * getZoneOrderLength(zone)" y="8" stroke="#000" fill="#000000"
				:filter="'url(#solid_green)'" @mousedown.stop="onMouseDown" style="cursor: default;opacity: 0.65;">
				{{ zone.order }} </tspan>
		</text>
		<!-- END TEXT BOXES-->


		<!-- SHOW LIST ITEM AND LABEL.  Need to have a fill on items and labels otherwise only the outline is clickable.-->
		<!-- LIST ITEMS. made the key different ie listitem.id -->
		<!-- using key="'A' + listitem.id" because that same key is used in other for rect for loops.  was getting dupe id message. -->

		<rect fill="#67ffae" fill-opacity="0" class="zone-list-items" :id="listitem.id" :parentId="zone.id"
			:listItemIndex="listitem.listItemIndex"
			v-show="!resizing && (zone.type == 'list' || zone.type == 'toc') && zone.listitems.length > 0"
			v-for="(listitem, i) in zone.listitems" :key="'A' + listitem.id" :index="i" :width="listitem.w" :height="listitem.h"
			:x="listitem.x" :y="listitem.y">
		</rect>

		<!-- LIST LABELS.  made the key different here ie listitem.index     Was getting duplicate keys found since we're iterating over the listitems twice: one for item and one for label-->
		<rect fill="#67ffae" fill-opacity="0" class="ui-list-label"
			v-show="!resizing && zone.type == 'list' && zone.listitems.length > 0 && zone.showListLabel && listitem.uselbl && listitem.lbl !== undefined"
			v-for="(listitem, item) in zone.listitems" :key="'B' + listitem.id" :index="item"
			:width="listitem.lbl != null ? listitem.lbl.w : 0" :height="listitem.lbl != null ? listitem.lbl.h : 0"
			:x="listitem.x + (listitem.lbl != null ? listitem.lbl.x : 0)"
			:y="listitem.y + (listitem.lbl != null ? listitem.lbl.y : 0)" :listItemIndex="listitem.listItemIndex"
			:parentId="listitem.id" stroke-width="1" stroke-linecap='butt' stroke-dashoffset="0" stroke-linejoin="square"
			stroke="#00000085" stroke-dasharray="1, 1">
		</rect>

		<!-- TOC LABELS ie dotted.  instead of listitem.lbl, it's listitem.artifact -->
		<rect fill="#67ffae" fill-opacity="0" class="ui-list-label"
			v-show="!resizing && zone.type == 'toc' && zone.listitems.length > 0 && zone.showArtifact && listitem.artifact !== undefined"
			v-for="(listitem, item) in zone.listitems" :key="'C' + listitem.id" :index="item"
			:width="listitem.artifact != null ? listitem.artifact.w : 0"
			:height="listitem.artifact != null ? listitem.artifact.h : 0"
			:x="listitem.x + (listitem.artifact != null ? listitem.artifact.x : 0)"
			:y="listitem.y + (listitem.artifact != null ? listitem.artifact.y : 0)" :listItemIndex="listitem.listItemIndex"
			:parentId="listitem.id" stroke-width="1" stroke-linecap='butt' stroke-dashoffset="0" stroke-linejoin="square"
			stroke="#00000085" stroke-dasharray="1, 1">
		</rect>


		<!-- THE RESIZE CORNER ALWAYS COMES LAST -->
		<!-- this is the lower right-hand corner resize triangle-->
		<svg id="resize" class="corner-resize" width="10" height="10" :x="zone.w - 10" :y="zone.h - 10" v-if="!resizing"
			@mousedown="onResizeDown">
			<path v-if="!creating && !resizing && resizableZoneType(zone)" d="M 0,10 10,0 v 10 z" fill="#00000012"
				style="cursor: nwse-resize !important" />
		</svg>

		<!-- THIS IS THE LIST SPLITTER.  Must go last because svg doesn't have a z-index; the order drawn is used instead -->
		<line class="ui-list-splitter" v-for="(splitterObj, index) in zone.splitterObjs"
			v-show="!resizing && (zone.type == 'list' || zone.type == 'toc')" :splitterIndex="index" :key="splitterObj.id" :x1="0"
			:y1="splitterObj.y" :x2="zone.w - 1" :y2="splitterObj.y" stroke="#6a6d6e" stroke-width="2.5" style=""
			@mousedown="onMouseDown" />

	</svg>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import EventBus from "@/eventBus"
import router from '../../../../router'

export default {
	//this is essentially akin to the eqZone directive from editor_directives
	name: "ZoneRect",
	components: {},
	//these are injected from the SVGZones component
	props: {
		zone: { type: Object, required: true },  //same as zoneModel from editor_directives
		selected: { type: Boolean, required: false },
		creating: { type: Boolean, required: false }, //prob not needed here.  handled in SVGZones.
		resizing: { type: Boolean, required: false }, //prob not needed here.  handled in SVGZones.
		focused: { type: Boolean, required: false }, //prob not needed here.
		initialPosition: { type: Object, required: false },
		resizingZone: { type: Object, required: false },

	},
	data() {
		return {
			zoneClass: ['zone'],  //holds array of string class names.  start with zone
			listSplitterIndex: null,
			listItemIndex: null,
		}
	},
	computed: {
		...mapGetters({
			zoneToggleSetting: "application/getZoneToggleSetting",
			zoomLevel: "application/getZoomLevel",
			zones: "page/getLayout",
			selectedListItem: "page/getSelectedListItem",
			selectedZoneList: "page/getSelectedZoneList",
			selectedZone: "page/getSelectedZone",
			options: "application/getOptions",

		}),
		zoneKey() {
			// console.log('zoneKey computed is updating')
			return this.zone.key
		},
		// show/hide box with zone type abbr.  depends on zoneInfo.
		showZoneType() {
			if (this.zoneToggleSetting == 1 || this.zoneToggleSetting == 3) {
				return true
			}
			return false
		},
		// show/hide box with zone order number. depends on zoneInfo.
		showZoneOrder() {
			if (this.zoneToggleSetting == 1 || this.zoneToggleSetting == 2) {
				return true
			}
			return false
		},
		/*
		 Need a way to take array of string class names and join to large space-separated string for the svg zone class
		*/
		getZoneClass() {
			//change to: if it's not selected, remove the class
			var retval = this.zoneClass.join(' ')
			return retval
		},
		rightEdgePathName() {
			return "rightEdge" + this.zone.id
		},
		rightEdgeDValue() {
			return 'M ' + this.zone.w + ', 0 v ' + this.zone.h;
		},
		rightEdgePathStyle() {
			return 'cursor: ew-resize, stroke: "#036485", strokeWidth: 2'
		},
		bottomEdgePathName() {
			return "bottomEdge" + this.zone.id
		},
		bottomEdgeDValue() {
			return 'M 1 ,' + (this.zone.h - 1).toString() + ' h ' + (this.zone.w - 2);
		},
		bottomEdgePathStyle() {
			return 'cursor: ns-resize, stroke: "#036485", strokeWidth: 2'
		}
	},
	watch: {
		/*
			We're watching the selected prop on the zone to know if we need to add or remove the selected class.

			Selected is ultimately set in the parent ie SVGZones, then the selected property is propegated to SVGZones, which
			is the child component.  If this component needs a zone set as selected, it sends an event to the parent to make
			the change see this.zoneSelected.  This watcher looks for changes to the selected property so we know when
			to add the class.  So, selected class is added in this component, but selected is added in the parent component.
			Goal is to have a one-way direction update.
		*/
		selected: {
			handler(newVal, oldVal) {
				if (newVal) {
					this.zone.selected = true
					this.addSelectedClass()
				}
				else {
					this.zone.selected = false
					this.removeSelectedClass()
					this.zoneUnselectListItem()
				}
			}
		},
		focused: {
			handler(newVal, oldVal) {
				if (newVal) {
					//this.addZoneClass('focused')
				}
				else {
					//this.removeZoneClass('focused')
				}
			}
		},
		//watch on zone model. here's the way to deep watch an object
		zone: {
			handler(newVal, oldVal) {
				// console.log('zone model has changed', newVal)
				this.zone.changed = true
				//zone type may have changed, so update props/styles etc
				if (!this.resizing) {
					this.updateZoneProperties()
				}
			},
			deep: true
		},

	},
	methods: {
		...mapMutations({
			//updateLayoutZone: "page/UPDATE_LAYOUT_ZONE",
			resizeListItems: "page/RESIZE_LIST_ITEMS",
			setSelectedZoneListSplitters: "page/SET_SELECTED_ZONE_LIST_SPLITTERS",
			setSelectedZoneList: "page/SET_SELECTED_ZONE_LIST",
			setSelectedListItem: "page/SET_SELECTED_LIST_ITEM",
			setSelectedZone: "page/SET_SELECTED_ZONE",
			setFocusedZone: "page/SET_FOCUSED_ZONE",
		}),
		/*
			Here is where we add/remove the selected class, which includes the ring highlight the is set in user options
		*/
		addSelectedClass() {
			this.removeZoneClass('zone')
			this.addZoneClass('selected')
			this.addZoneClass(this.getRingWidthClass())
		},
		removeSelectedClass() {
			this.addZoneClass('zone')
			this.removeZoneClass('selected')
			this.removeZoneClass(this.getRingWidthClass())
		},
		getRingWidthClass() {
			var ring_width = this.options.ring_width
			if(ring_width == null || ring_width == ''){
				ring_width = 'Large'
			}
			
			if (ring_width == 'Large') {
				return 'large_ring'
			} else if (ring_width == 'Medium') {
				return 'medium_ring'
			} else if (ring_width == 'Small') {
				return 'small_ring'
			} else if (ring_width == 'Off') {
				return 'off_ring'
			} else {
				return ''
			}
		},

		resizableZoneType(zone) {
			return zone.type != 'image' && zone.type != 'lbl' && zone.type != 'lbody'
		},

		reverseArray(array) {
			if (array != null) {
				var retval = []
				for (var i = array.length - 1; i >= 0; i--) {
					retval.push(array[i])
				}
				// console.log('array: ', array)
				// console.log('reversed:', retval)
				return retval
			}

		},

		//THIS SECTION IS FOR ADDING/REMOVING CLASSES ON ZONE

		//add string class name to array of classes applied to zone rect
		addZoneClass(zClass) {
			//console.log('adding zClass: ' + zClass)
			//need way to add zone class
			if (!this.hasZoneClass(zClass)) {
				this.zoneClass.push(zClass)
			}
		},
		//remove string class name from array of classes applied to zone rect
		removeZoneClass(zClass) {
			//console.log('removing zClass: ' + zClass)
			for (var i = 0; i < this.zoneClass.length; i++) {
				if (this.zoneClass[i] == zClass) {
					this.zoneClass.splice(i, 1)
					break
				}
			}
		},
		//return true if array of zone rect class names included string class
		hasZoneClass(zClass) {
			var found = false
			for (var i = 0; i < this.zoneClass.length; i++) {
				if (this.zoneClass[i] == zClass) {
					return true
				}
			}
			return false
		},

		/*
			Get all the elements with a class name
			param zClass string class name
		*/
		getAllElementsWithClass(zClass) {
			var allElements = document.querySelectorAll('.' + zClass)
			return allElements
		},

		/*
			Remove a class name from a list of elements
		*/
		removeClassFromElements(zClass, elements) {
			elements.forEach((element) => {
				element.classList.remove(zClass)
			})
		},

		/*
			Get element by id and add a class to it
		*/
		addClassToElementById(zClass, elementId) {
			var element = document.getElementById(elementId)
			element.classList.add(zClass)
		},

		/*
			Get element by id and remove a class from it
		*/
		removeClassFromElementById(zClass, elementId) {
			var element = document.getElementById(elementId)
			element.classList.remove(zClass)
		},

		/*
			This triggered by the zone model watcher, so if a zone changes this gets invoked.
			When the page is loaded, this is called for each zones as a 'newVal'
		*/
		updateZoneProperties() {
			this.zone.source == 'OCR' ? this.addZoneClass('ocr') : this.removeZoneClass('ocr')
			this.zone.type == 'sensitivity' ? this.addZoneClass('sens_zone') : this.removeZoneClass('sens_zone')
			this.zone.type == 'div' ? this.addZoneClass('div_zone') : this.removeZoneClass('div_zone')
			this.zone.visible != undefined && this.zone.visible == false ? this.addZoneClass('not-visible') : this.removeZoneClass('not-visible')
			this.zone.altSelected ? this.addZoneClass('alt-selected') : this.removeZoneClass('alt-selected')

			//don't think this is needed
			if (this.zone.type == 'table') {
				// find table div and add table style for width and height  see ln# 115
			}

			if (this.zone.type == 'list' || this.zone.type == 'toc') {
				this.addZoneClass('list')
			}
			else {
				//not a list or toc
				this.removeZoneClass('list')
			}

			//hover style at end so one case doesn't shut off another case
			if (this.zone.visible == false ||
				this.zone.source == 'OCR' ||
				this.zone.type == 'div' ||
				this.zone.type == 'sensitivity') {
				this.addZoneClass('disable-hover')
			}
			else {
				this.removeZoneClass('disable-hover')
			}

			if (this.zone.focused) {
				console.log('*** zone is focused!')
			}

		},

		//on keyup enter, select the zone.  ref'd on main svg.
		zoneSelected(zone) {
			this.setSelectedZone(zone)
			//adding this because newly created zones aren't picking up the selected class correctly
			this.addSelectedClass()
			EventBus.$emit("zoneSelected", zone)
		},

		onFocus() {
			this.setFocusedZone(this.zone)
		},

		getTextTranslate() {
			return 'translate(' + this.zone.x + 2 + ',' + this.zone.y + 2 + ')'
		},

		/*
			Copied from SVGZones.vue since it's required for some of the zone-type-specific mouse
			handlers
		*/
		getCurrentPosition(event) {
			var x_offset = (this.zoomLevel - 1) * 36 + 320
			var x = event.pageX - x_offset
			var y_offset = (this.zoomLevel - 1) * 25 + 160
			var y = event.pageY - y_offset
			return { x: Math.round(x), y: Math.round(y) }
		},

		/*
		Handles double-click on zone rect.  Fixed.
		*/
		onDoubleClick(e) {
			// console.log('in double click.  target is: ', e.target)
			var dblClickDivtarget = e.target
			var listIndex = null
			var parentId = null
			if (dblClickDivtarget.classList.contains("zone-list-items")) {
				parentId = e.target.attributes.getNamedItem('parentId').value
				this.listItemIndex = parseInt(e.target.attributes.getNamedItem('listItemIndex').value)
				//if is selected, unselect, otherwise select
				if (this.isZoneSelectListItem(parentId, this.listItemIndex)) {
					this.zoneUnselectListItem()
				}
				else {
					this.zoneSelectListItem(parentId, this.listItemIndex)
				}
			}
			else if (dblClickDivtarget.classList.contains("ui-list-label")) {
				var list_item_element_id = e.target.attributes.getNamedItem('parentId').value
				var list_item_element = document.getElementById(list_item_element_id)
				this.listItemIndex = parseInt(list_item_element.attributes.getNamedItem('listItemIndex').value)
				parentId = list_item_element.attributes.getNamedItem('parentId').value
				//if is selected, unselect, otherwise select
				if (this.isZoneSelectListItem(parentId, this.listItemIndex)) {
					this.zoneUnselectListItem()
				}
				else {
					this.zoneSelectListItem(parentId, this.listItemIndex)
				}
			}
			else {
				EventBus.$emit("zoneDblClicked", {})
			}

		},

		//Is a list item selected? if we dbl click on one, we want to unselect it if it was selected
		isZoneSelectListItem(parentId, listItemIndex) {
			var retval = false
			// console.log('in isZoneSelectListItem.  parentId: ', parentId)
			// console.log('in isZoneSelectListItem.  listItemIndex: ', listItemIndex)
			// console.log('this.selectedListItem.parentId: ', this.selectedListItem != null ? this.selectedListItem.parent : null)
			// console.log('this.selectedListItem.listItemIndex:', this.selectedListItem != null ? this.selectedListItem.listItemIndex : null)

			if (this.selectedListItem != null && this.selectedListItem.parent == parentId && this.selectedListItem.listItemIndex == listItemIndex) {
				// console.log('this is the selected list item!!')
				retval = true
			}
			return retval
		},
		onResizeRight(e) {
			this.$emit("resizeRight",
				{ zone: this.zone, evt: e }
			)
		},
		onResizeBottom(e) {
			this.$emit("resizeBottom",
				{ zone: this.zone, evt: e }
			)
		},
		/*
			onMouseDown is callback on mousedown event for rect/zone
			all this method is really doing is emitting an event that gets picked up by the parent
			by way of @mousedown="zoneMouseDown"  on the SVGZone included component
		*/
		onMouseDown(e) {
			e.preventDefault()
			//this.runTestCode()
			var movingTarget = e.target

			var fieldResizing = false
			if (this.zone.type == 'field') { //TODO: need to add check if cust has 'pro' for fields
				fieldResizing = true
			}

			/*
				Various mouse listener logic triggered from mousedown on a zone.
				Mousedown might mean that things are being dragged, so this handles the special zone-type-specific
				cases
			*/
			if (this.zone.type != 'image' && this.zone.type != 'lbl' && this.zone.type != 'lbody' && this.zone.type != 'field') {   //TODO: fix.  s/b && fieldResizing ??
				//special case for ui-list-splitter
				if (e.target.classList.contains('ui-list-splitter')) {
					this.movingListSplitter = e.target.classList.contains("ui-list-splitter")

					//save the index of the splitter we clicked on so we can use it in mousemovesplitter
					this.listSplitterIndex = parseInt(movingTarget.attributes.getNamedItem('splitterIndex').value)

					window.addEventListener('mousemove', this.mouseMoveSplitter)
					window.addEventListener('mouseup', this.mouseupSplitter)
				}
				//special case for zone-list-items-selected
				else if (e.target.classList.contains('zone-list-items-selected')) {
					var nope = true
					// console.log('nope')
				}
				//special case for ui-list-label
				else if (e.target.classList.contains('ui-list-label')) {
					//this replaces the old way of trying to get the list item div the label is nested in: var list_item_element = e.target.parentElement
					//there is no nesting of svg rectangles, so we add a parent id to the label rectangle so we
					//can get the list item element that the label is on top of
					var list_item_element_id = e.target.attributes.getNamedItem('parentId').value
					var list_item_element = document.getElementById(list_item_element_id)
					// console.log('*** parent element is: ', list_item_element)

					if (list_item_element.classList.contains("zone-list-items")) {
						// console.log('sorting list items')
						this.zone.listitems.sort(function (a, b) {
							if (a.listItemIndex < b.listItemIndex)
								return -1
							if (a.listItemIndex > b.listItemIndex)
								return 1
							return 0
						})

						//aka listIndex
						this.listItemIndex = parseInt(list_item_element.attributes.getNamedItem('listItemIndex').value)

						//replacement for emitting "selectListItem"
						this.zoneSelectListItem(list_item_element.attributes.getNamedItem('parentId').value, this.listItemIndex)
					}
					window.addEventListener('mousemove', this.mouseMoveLbl)
					window.addEventListener('mouseup', this.mouseUpLbl)
				}
				//if none of the above, emit mousedown that will be picked up by zoneMouseDown in SVGZones where
				//general mousemove and mouseup handling is done
				else {
					//Mousedown listener on rect is handled by this function and emits a mousedown event
					//picked up by SVGZones.vue zoneMouseDown

					//SVGZones zoneMouseDown sets selected zone
					//console.log('emitting mousedown from onMouseDown in zone')
					this.$emit("mousedown",
						{ zone: this.zone, evt: e }
					)
				}
			}
			else {
				//TODO: fix this one
				console.log('trying to click tabZones')
				//document.getElementById('tabZones').click();
			}

			/*
				This is after the IF condition, so it's handling selecting a zone that
				an image, lbl, or lbody.
			*/
			this.clickZonesTab()
			if (this.zone && this.zone.type != 'list') {
				this.zoneSelected(this.zone)
			}
		},

		/*
			This is a replacement for the zoneSelectListItemListener from document_controller
		*/
		zoneSelectListItem(listId, listIndex) {
			// console.log('selecting list id:', listId)
			for (var i = 0; i < this.zones.length; i++) {
				if ((this.zones[i].type === 'list' || this.zones[i].type === 'toc') && this.zones[i].id.toString() == listId.toString()) {

					if (this.selectedZone !== this.zones[i]) {
						var allZoneElements = this.getAllElementsWithClass('zone')
						this.removeClassFromElements('zone-list-selected', allZoneElements)

						var allZoneListItemElements = this.getAllElementsWithClass('zone-list-items')
						this.removeClassFromElements('zone-list-items-selected', allZoneListItemElements)
					}

					if (this.selectedZoneList == null || this.selectedZoneList === undefined ||
						this.selectedListItem == null || this.selectedListItem === undefined ||
						this.selectedZoneList.id !== listId || (this.selectedListItem !== undefined && parseInt(listIndex) !== this.selectedListItem.listItemIndex)) {

						// console.log('in if')
						this.clickZonesTab()
						this.zoneSelected(this.zones[i])
						this.setSelectedZoneList(this.zones[i])
						this.setSelectedListItem(undefined)

						var allListItemElements = this.getAllElementsWithClass('zone-list-items')
						this.removeClassFromElements('zone-list-items-selected', allListItemElements)

						EventBus.$emit("ADJUST_LIST_SENSITIVITY", {})
						this.addClassToElementById('zone-list-selected', listId)

						if (this.selectedZoneList.listitems !== undefined && listIndex <= this.selectedZoneList.listitems.length) {
							for (var li = 0; li < this.selectedZoneList.listitems.length; li++) {
								if (this.selectedZoneList.listitems[li].listItemIndex === parseInt(listIndex)) {
									this.setSelectedListItem(this.selectedZoneList.listitems[li])

									this.addClassToElementById('zone-list-items-selected', this.selectedListItem.id)
									break
								}
							}
						}
					}
				}
			}
		},
		/*
			Undo the zoneSelectListItem
		*/
		zoneUnselectListItem() {
			// console.log('unselecting list item')
			this.setSelectedListItem(undefined)
			//remove classes
			var allListItemElements = this.getAllElementsWithClass('zone-list-items')
			this.removeClassFromElements('zone-list-items-selected', allListItemElements)
		},

		//Need mousMoveLbl from eqZone. TODO: change the style setting on div zone to svg zone
		mouseMoveLbl(e) {
			var initialPosition = this.initialPosition
			var currentPosition = this.getCurrentPosition(e)
			var newX = currentPosition.x - initialPosition.x
			var newY = currentPosition.y - initialPosition.y
			var movingTarget = e.target
			// console.log('in mouseMoveLbl.   event is: ', e)

			//listItemIndex added to label rect and to list item rect
			//var listIndex = movingTarget.attributes.getNamedItem('listItemIndex').value
			var listIndex = this.listItemIndex
			// console.log('list item index val: ', listIndex)
			if (this.zone.listitems[listIndex].lbl !== undefined) {

				if ((this.zone.listitems[listIndex].lbl.x + newX) < 0)
					newX = 0

				if (this.zone.listitems[listIndex].lbl.x + this.zone.listitems[listIndex].lbl.w + newX > this.zone.listitems[listIndex].w)
					newX = 0

				if ((this.zone.listitems[listIndex].lbl.y + newY) < 0)
					newY = 0

				if (this.zone.listitems[listIndex].lbl.y + this.zone.listitems[listIndex].lbl.h + newY > this.zone.listitems[listIndex].h) {
					newY = 0
				}

				this.zone.listitems[listIndex].lbl.x += newX / this.zoomLevel
				this.zone.listitems[listIndex].lbl.y += newY / this.zoomLevel

				initialPosition.x = currentPosition.x
				initialPosition.y = currentPosition.y
			}
			else if (this.zone.listitems[listIndex].artifact !== undefined) {

				if ((this.zone.listitems[listIndex].artifact.x + newX) < 0)
					newX = 0

				if (this.zone.listitems[listIndex].artifact.x + this.zone.listitems[listIndex].artifact.w + newX > this.zone.listitems[listIndex].w)
					newX = 0

				if ((this.zone.listitems[listIndex].artifact.y + newY) < 0)
					newY = 0

				if (this.zone.listitems[listIndex].artifact.y + this.zone.listitems[listIndex].artifact.h + newY > this.zone.listitems[listIndex].h) {
					newY = 0
				}

				this.zone.listitems[listIndex].artifact.x += newX / this.zoomLevel
				this.zone.listitems[listIndex].artifact.y += newY / this.zoomLevel

				initialPosition.x = currentPosition.x
				initialPosition.y = currentPosition.y
			}

			this.setSelectedZone(this.selectedZone)
		},

		//Need mouseUpLbl from eqZone
		mouseUpLbl(e) {
			window.removeEventListener("mousemove", this.mouseMoveLbl)
			window.removeEventListener("mouseuup", this.mouseUpLbl)
		},

		//Need mouseMoveSplitter from eqZone. TODO: needs to be fixed from div styling to svg styling
		//the newY is only going to move 1px at a time each time this method function is triggered
		mouseMoveSplitter(event) {
			//the initialPosition is a property from the zones component.  if no zone is clicked on, it will be null.
			//we want the splitters to be able to move without clicking on a zone, so if it's null, set it local and pass
			//a message to zones to update the initial position
			if (this.initialPosition == null) {
				this.initialPosition = this.getCurrentPosition(event)
				this.updateInitialPosition(event)
			}
			var initialPosition = this.initialPosition
			var currentPosition = this.getCurrentPosition(event)
			var newY = currentPosition.y - initialPosition.y
			var movingTarget = event.target

			//use variable saved from the onmousedown so we don't worry about slidding off of the splitter and losing the target
			var listSpltterIndex = this.listSplitterIndex

			//console.log('list splitter index: ', listSpltterIndex)

			if ((this.zone.splitterObjs[listSpltterIndex].y + newY) < 0) {
				newY = 0
			}

			// Hitting the previous splitter
			if (listSpltterIndex > 0 && (this.zone.splitterObjs[listSpltterIndex].y + newY) < (this.zone.splitterObjs[listSpltterIndex - 1].y)) {
				newY = 0
			}

			var maxHeight = this.zone.h - 4;
			if (this.zone.splitterObjs.length > listSpltterIndex + 1) {
				maxHeight = this.zone.splitterObjs[listSpltterIndex + 1].y
			}

			// If hitting the next splitter
			if (((this.zone.splitterObjs[listSpltterIndex].y + newY)) > maxHeight - 2) {
				newY = 0
			}

			//need to update both the splitterObj that we're using to manipulate the ui
			this.zone.splitterObjs[listSpltterIndex].y += newY / this.zoomLevel
			//AND the actual splitters that the rest of the program uses for list processing
			this.zone.splitters[listSpltterIndex] += newY / this.zoomLevel

			this.initialPosition.x = currentPosition.x
			this.initialPosition.y = currentPosition.y

		},

		//need mouseupSplitter.  TODO: fix
		mouseupSplitter(e) {
			this.resizeListItems(this.zone)
			window.removeEventListener('mousemove', this.mouseMoveSplitter)
			window.removeEventListener('mouseup', this.mouseupSplitter)
		},

		/*
			Handler for mousedown event listener on zone resize corner
		*/
		onResizeDown(e) {
			// console.log('resize down event on resize corner:', e.composedPath()[1].id)
			var target = e.target
			if (e.composedPath()[1].id == "resize") {
				// console.log('found resize')
				console.log(document.getElementById("resize").classList)
			}
			//console.log('emitting resizeDown')
			this.$emit("resizeDown",
				{ zone: this.zone, evt: e }
			)
		},

		//THIS SECTION IS FOR THE TINY RECTANGLES WITH ZONE ABBR AND ORDER NUMBER

		//get the length of the zone abbr so we know how long to make the zone type rectangle
		getZoneTypeAbbrLength(zoneModel) {
			var retval = 0
			retval = this.getZoneTypeAbbr(zoneModel).length
			return retval
		},
		//get the length of the order so we know how long to make the order rectangle
		getZoneOrderLength(zoneModel) {
			var retval = 1
			if (zoneModel && zoneModel.order) {
				retval = zoneModel.order.toString().length
			}
			// console.log("getZoneOrderLength", retval)
			return retval
		},
		//need way to get zone abbr for upper left-hand corner box
		getZoneTypeAbbr(zoneModel) {

			if (zoneModel) {
				var retval = null;
				var lookup = [{ name: 'anchor', abbr: 'a' },
				{ name: 'blockquote', abbr: 'bq' },
				{ name: 'cite', abbr: 'ct' },
				{ name: 'div', abbr: 'div' },
				{ name: 'field', abbr: 'fld' },
				{ name: 'footnote', abbr: 'fn' },
				{ name: 'footnotelink', abbr: 'fnl' },
				{ name: 'form', abbr: 'frm' },
				{ name: 'graphic', abbr: 'gph' },
				{ name: 'heading', abbr: 'h' + zoneModel.headerlevel },
				{ name: 'ignore', abbr: 'ign' },
				{ name: 'image', abbr: 'img' },
				{ name: 'link', abbr: 'lnk' },
				{ name: 'list', abbr: 'l' },
				{ name: 'listitem', abbr: 'li' },
				{ name: 'lbl', abbr: 'lbl' },
				{ name: 'lbody', abbr: 'lbody' },
				{ name: 'ocr', abbr: 'ocr' },
				{ name: 'OCR', abbr: 'ocr' },
				{ name: 'quote', abbr: 'qt' },
				{ name: 'sensitivity', abbr: 'sens' },
				{ name: 'table', abbr: 'tb' },
				{ name: 'toc', abbr: 'toc' },
				{ name: 'tocartifact', abbr: 'atf' },
				{ name: 'text', abbr: 'tx' }]

				var getAbbr = function (name) {
					retval = null;
					for (var i = 0; i < lookup.length; i++) {
						if (lookup[i].name == name) {
							retval = lookup[i].abbr;
						}
					}
					return retval;
				}

				if (zoneModel.type === 'list' && zoneModel.toc) {
					return 'TOC'
				}
				else {
					return getAbbr(zoneModel.type).toUpperCase();
				}
			}

		},
		/*
			Method to hold testing code for elements, events, etc
		*/
		runTestCode() {
			//console.log('classlist: ', e.target.classList)
			//if(e.target.classList.contains("selected")){
			//	console.log('selected is in classList')
			//}
			//console.log('zone mouse down zone: ', this.zone)
			//console.log('classlist: ', e.target.classList)
			//console.log('classList: ', this.getZoneClass)
			//console.log('emitting mousedown')
			//this.zone.selected = true   //not necessary
		},

		clickZonesTab() {
			EventBus.$emit("tabZones", {})
		},
		updateInitialPosition(e) {
			EventBus.$emit("updateInitialPosition", e)
		}
	},
	mounted() {
		this.zone.changed = false
		
		EventBus.$on("add-zone-class", (zClass) => {
			// console.log('add zone class picked up: ', zClass)
			this.addZoneClass(zClass)
		})

		EventBus.$on("remove-zone-class", (zClass) => {
			// console.log('remove zone class picked up: ', zClass)
			this.removeZoneClass(zClass)
		})
	},

	beforeDestroy() {
		EventBus.$off('add-zone-class')
		EventBus.$off('remove-zone-class')
	}

}
</script>

<style lang="scss" scoped>
.zone {
	fill: '#e8ffb657';
	stroke: #767676;
	stroke-width: 2;

}

.zone:not(.disable-hover):hover {
	fill: #D4FFC0;
	fill: #d4ffc0;
	fill: rgba(212, 255, 192, 0.49);
	/* Works on all modern browsers */
	border: 1px solid #000000;
	cursor: move;
}

.highlight {
	border: 1px solid #F50808 !important;
	background-color: #f508082b !important;
	z-index: 100;
}

//in angular vers, highlight was the class for selected items.  in vue, the class is selected
.selected {
	fill: #f508082b !important; //need to override just about everything but not-visible
	stroke: #F50808 !important;
	stroke-width: 2;
}

.selected:hover {
	fill: #ff000045;
	cursor: move;
}

.ocr {
	fill: #4baafa57;
}

.not-visible {
	fill: url(#not_visible) !important;
}

.disable-hover {

}


svg:focus {
	outline: 5px solid #4baafa57 !important;
}


/* zone ring sizes. There are more !important than those app.css */
svg:focus:has(.large_ring){
	outline: 5px solid #4baafa57 !important;
}

svg:focus:has(.medium_ring) {
  outline: 3px solid #4baafa57 !important;
}

svg:focus:has(.small_ring) {
  outline: 1px solid #4baafa57 !important;
}

svg:focus:has(.off_ring){
	outline: 0px solid #4baafa57 !important;
}

/*
svg:has(.focused:not(.list, .zone-list-items, .ui-list-label, .ui-list-splitter)) {
	outline: 5px solid #4baafa57 !important;
}
*/

.sens_zone {
	fill: #00000000;
	stroke: green !important;
	stroke-width: 4;
}

.sens_zone.selected {
	fill: #00000000;
	stroke: green !important;
	stroke-width: 8;
}

.div_zone {
	fill: #00000000;
	stroke: gray !important;
	stroke-width: 4;
}

.div_zone.selected {
	fill: #00000000 !important;
	stroke: gray !important;
	stroke-width: 8;
}

.corner-resize {
	cursor: nwse-resize !important;
}

.alt-selected {
	stroke: blue !important;
}

.zone-list-items {
	position: absolute;
	cursor: move;
}

.zone-list {
	background-color: #67ffae;
	width: 5%;
	-moz-opacity: 0.8;
	-khtml-opacity: 0.8;
	opacity: 0.8;
	cursor: move;
}

.zone-list-items-selected {
	fill: #2ecaf861 !important;
	fill-opacity: 10;
}

.ui-list-splitter {
	//position: absolute;
	background-color: #6a6d6e !important;
	cursor: ns-resize;
	//height: 2px;
	//width: 100px;
	//z-index: 120;
}

.ui-list-label {
	position: relative;
	border: 1px dotted #00000085;
	z-index: 125;
	cursor: move;
}

.zone-toc {
	background-color: #e9ff67;
	width: 5%;
	height: 100%;
	-moz-opacity: 0.8;
	-khtml-opacity: 0.8;
	opacity: 0.8;
}

.left {
	float: left;
}

.right {
	float: right;
}


</style>